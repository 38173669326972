<template>
    <div class="__transaction_history">
        <div class="to__nav">
            <div class="__navigation_content">
                <button @click="toggleCurrentHistory('all')" class="__nav_btn"
                    :class="[activeHistory == 'all' ? 'btn_active' : '']">{{ $t('dashboard.wallet.all') }}</button>
                    <button @click="toggleCurrentHistory('debt')" class="__nav_btn"
                    :class="[activeHistory == 'debt' ? 'btn_active' : ''],showDebt" v-if="isShowingDebt">{{ $t('dashboard.wallet.debt') }}</button>
                <button @click="toggleCurrentHistory('loadwallet')" class="__nav_btn"
                    :class="[activeHistory == 'loadwallet' ? 'btn_active' : '']">{{ $t('dashboard.wallet.laod_wallets') }}</button>
                <button @click="toggleCurrentHistory('bundlepurchase')" class="__nav_btn"
                    :class="[activeHistory == 'bundlepurchase' ? 'btn_active' : '']">{{ $t('dashboard.wallet.bundle-purchase') }}</button>
            </div>
        </div>
        <div class="__transactions__histories">
            <transition>
                <component :is="currentHistory"> </component>
            </transition>
        </div>
    </div>
</template>

<script>
import All from './transactionhistory/all.vue'
import LoadWallet from './transactionhistory/loadwallets'
import BundlePurchase from './transactionhistory/bundlepurchase.vue'
import debt from './transactionhistory/debt.vue'
import store from '@/state/store.js'
export default {
    name: 'transaction-history',
    components: {
        All, LoadWallet, BundlePurchase,debt
    },
    data() {
        return {
            currentHistory: All,
            activeHistory: 'all',
            showDebt:false
        }
    },
    mounted() {
        store.dispatch('wallet/purchaseBundleHistory')
        store.dispatch('wallet/depositHistory')
        store.dispatch('wallet/displayDebt')

    },
    methods: {
        toggleCurrentHistory(type) {
            if (type === 'all') {
                this.activeHistory = 'all'
                return this.currentHistory = All
            } else if (type === 'loadwallet') {
                this.activeHistory = 'loadwallet'
                return this.currentHistory = LoadWallet
            } else if (type === 'bundlepurchase') {
                this.activeHistory = 'bundlepurchase'
                return this.currentHistory = BundlePurchase
            } else {
                this.activeHistory = 'debt'
                return this.currentHistory =debt
            }
        },
        showdebt(){
        store.dispatch('wallet/dispalyDebt')
      }
    },
    
    mounted() {
          store.dispatch('wallet/displayDebt').then(() => {
                this.showDebt = true
            }).catch((err) => {
               if (err.response.status ===404){
                this.showDebt = false
               }
            })
    },

    computed:{
        isShowingDebt(){
            return this.showDebt
        }
    }

}
</script>

<style lang="scss" scoped>
@import url('../../../styles/wallet/transactionhistory.scss');

.__transaction_history {
    height: 57vh;
    padding-bottom: 2rem;
    // background-color: red;
    overflow-y: scroll;
}
.to__nav{
    position: sticky;
    top: 0;
    background-color: #fafafa;
}

</style>