
export default [
    {
        id:2,
        title:'Basic',
        amount:'100',
        description:'Optimal for most business that send alot of bulk SMS packages',
        validity:'30 Validity',
        sms_per_amount:"",
        total_sms:"4400 SMS"
    },
    {
        id:3,
        title:'Basic',
        amount:'200',
        description:'Optimal for most business that send alot of bulk SMS packages',
        validity:'30 Validity',
        sms_per_amount:"",
        total_sms:"9030 SMS"
    },
    {
        id:4,
        title:'Basic',
        amount:'500',
        description:'Optimal for most business that send alot of bulk SMS packages',
        validity:'90 Validity',
        sms_per_amount:"",
        total_sms:"26900 SMS"
    },

    {
        id:5,
        title:'Pro',
        amount:'1000',
        description:'Optimal for most business that send alot of bulk SMS packages',
        validity:'90 Validity',
        sms_per_amount:"",
        total_sms:"53200 SMS"
    },


    {
        id:6,
        title:'Pro',
        amount:'2000',
        description:'Optimal for most business that send alot of bulk SMS packages',
        validity:'90 Validity',
        sms_per_amount:"",
        total_sms:"112000 SMS"
    },
    {
        id:7,
        title:'Pro',
        amount:'3000',
        description:'Optimal for most business that send alot of bulk SMS packages',
        validity:'182 Validity',
        sms_per_amount:"",
        total_sms:"170000 SMS"
    }
    
]


