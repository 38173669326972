<template>
      <div>  
        <ul class="fixed-headers"> 
          <li>Payable Amount:{{ debt.payable_amount.toLocaleString('en-US', { style: 'currency', currency: 'GHC' }) }}</li>
          <li>Paid Amount:{{ debt.paid_amount.toLocaleString('en-US', { style: 'currency', currency: 'GHC' }) }}</li>
          <li>Amount Due :{{ debt.due.toLocaleString('en-US', { style: 'currency', currency: 'GHC' }) }}</li> 
            <li v-if="debt.status ==0">Status:<span class="due">Due </span></li>
          <li v-if="debt.status ==1" >Status: <span class="completed">Completed</span>
          </li>
            
        </ul>
      </div>
</template>

<script>
import store from '@/state/store.js'
export default {
   name:  "debt",
    data() {
      return {
      transactions: [], 
      showingDebt: false,
      }
    },
    methods: {
      
    },
   mounted() {

            store.dispatch('wallet/displayDebt').then(() => {
                this.showingDebt = true
            }).catch((err) => {
               if (err.response.status ===404){
                this.showingDebt = false
               }
            })
    },
    computed:{
    debt(){
        return store.getters['wallet/debtAmount']
    } 
  },
}

</script>

<style scoped>
    ul{
      list-style: none;
    }

    .fixed-headers{
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      padding: 0 ;
      margin: 0;
      text-align: center;
      color: rgb(56, 56, 56);
     
    }
    .fixed-headers,.debt-content{
        color: rgb(53, 52, 52);
        margin-bottom:10px;
        padding:10px;
        border:1px solid #ff7e0e;
        border-radius:5px;
        font-size: 14px;
    }
    .due{
        color: red;
        

    }
    .completed{
        color:green;
        

    }
   

</style>