<template>
    <div class="__buy_bundle">
        <div class="row align-items-center justify-content-center">
            <div class="col-md-12 text-center">
                <div class="__top__text">
                    <p class="__top__description">
                    Our Enterprise Package is for individuals and Corporates
                    who have a fair idea of how many SMS they consume over a specific period
                </p>
                </div>
                <!-- <button id="__flexible" @click="showBuyFlexibleBundle">Buy Flexible offers</button> -->
            </div>
        </div>
        <div class="row justify-content-between __wallet__wrapper">
            <div class="__wallet__card__inner__container" v-for="data in enterpriseData" :key="data.id">
                <WalletCard @choseBundle="choseBundle" :userSelectedData="userSelectedData" :cardData="data" />
            </div>
        </div>
        <!-- MODAL CONTENT -->
        <!-- CONFIRM PURCHASE MODAL -->
        <div class="modal fade" id="flexiblepayment" tabindex="-1" role="dialog" aria-labelledby="group" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered " role="document">
                <div class="modal-content">
                    <div class="flexible__modal__header pt-4 pb-2">
                        <h5 class="modal-title __modal__header__text" id="flexiblepaymentLabel">
                            Flexible payment
                        </h5>
                        <p class="__flexible_payment_message">
                            Enter any amount and see how much SMS messages you can send
                        </p>
                    </div>
                    <div class="modal-body m-0 p-0">
                        <div class="row __form__main_wrapper justify-content-center" style="padding-right:1em; padding-left:1em;">
                            <div class="col-md-12 ">
                                    <el-form :model="inputFormData" :rules="rules" ref="applyForm"  @submit.prevent.native="checkEnter">
                                        <div class="__inner__form__container">
                                            <div class="form_input_container input___amount">
                                                <el-form-item  prop="amount" label="Amount">
                                                    <el-input 
                                                    style="width:100%; height:48px;"
                                                        type="text" placeholder="Enter Amount"  autocomplete="false"  
                                                        v-model="inputFormData.amount"></el-input>
                                                </el-form-item>
                                            </div>
                                            <div class="form_input_container input___message">
                                                    <el-form-item  prop="messages" label="Messages">
                                                    <el-input 
                                                    style="width:100%"
                                                    type="text" placeholder="messages"  autocomplete="false"  
                                                    v-model="inputFormData.messages"></el-input>
                                                </el-form-item>
                                            </div>
                                            <div class="__buy__btn">
                                                    <el-button  :loading="loading"
                                                    type="primary" style="height:51px; width:70px"
                                                    @click="buy('applyForm')">Buy</el-button>
                                            </div>
                                        </div>
                                    </el-form>
                                    <div class="color: #36D962;" v-if="success">
                                        {{success}}
                                    </div>
                                    <div v-if="success" >
                                        {{error}}
                                    </div>
                            </div>
                        </div>
                        <div class="" style="width:100%;text-align:center; margin-bottom:2em;" >
                            <button  class="close_btn" @click="hideModal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery'
import WalletCard from './walletCard.vue'
import enterpriseCardData from '../../../helpers/enterprise.js'
import Storage from '@/services/storage.js'

export default {
    name:'buy-bundle',
    components: {
        WalletCard
    },
    data() {
        return {
            userSelectedData:{},
            success:'',
            error:'',
            loading:false,
            inputFormData: {
                amount:'',
                messages:'', 
            },
            rules: {
                amount: [{required: true, message:'Amount is required', trigger:'blur',}],
                messages: [{required: true, message:'Amount is required', trigger:'blur',}]
            }
        }
    },
    computed: {
         enterpriseData(){
            const allEnterpriceData = enterpriseCardData
            const storage = new Storage()
            let isCoporate = storage.getSavedState('isCorporate') 
            if(!isCoporate) return enterpriseCardData
            return allEnterpriceData.filter( retail => retail.amount >= 100)
        },
    },
    mounted() {
        store.dispatch('wallet/accountBalance')
    },
    methods: {
         choseBundle(val) {
            this.userSelectedData = val
        },
        showBuyFlexibleBundle(){
            $('#flexiblepayment').modal('show')
        },
        buy(formName){
            this.$refs[formName].validate((valid) => {
                if(valid) {
                    this.promoLoading = true
                    this.proMoSuccess = null
                    this.proMoError = null
                    store.dispatch('auth/register', this.formData)
                    .then( response => {
                        if(response) {
                            this.proMoSuccess = response.data.message
                            this.promoLoading = false
                        }
                    })
                    .catch( err => {
                        this.promoLoading = false
            
                        if(err.response.data == undefined) {
                            this.proMoError = "An error occured try again."
                        }
                        if(err.response && err.response.status === 404) {
                            this.proMoError= err.response.data.data
                        }
                    })
                } else {
                    return false;
                }

            });
        },
        hideModal(){
            $('#flexiblepayment').modal('hide')
        },
    
    }
}
</script>

<style scoped>
.__top__text{
    width:100%;
    display:flex;
    justify-content:center;
}
.__top__description{
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #484A4F;
    max-width: 427.24px;

}
#__flexible{
    width: 210.89px;
    height: 48px;
    background: #F7921C;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 159.3%;
    text-align: center;
    text-transform: capitalize;
    color: #FFFFFF;
    outline:none;
    border:none;
    margin-top:1em;
    margin-bottom:2em;
}
.__inner__form__container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.__inner__form__container{
    width:100%;
    margin-right: 0px!important;
    padding-right: 0px!important;
}
.input___amount{
    width:50%;
}
.input___message{
    width:33%;
}
.__buy__btn{
    width:70px;
    margin-top:1.5em;
}
.__modal__header__text{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #484A4F;
}
.__flexible_payment_message{

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    color: #484A4F;

}
.flexible__modal__header{
    margin-left: 3em;
}
.__form__main_wrapper{
    margin-left: 1em;
    margin-right: 1em;
}
.close_btn{
    width: 84px;
    height: 51px;
    left: 254.76px;
    top: 199.59px;
    background: #F5F5F5;
    border-radius: 5px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 159.3%;
    text-align: center;
    color: #979797;
    outline: none;
    border:none;
    margin-top: 1em;
}

@media screen and (max-width:500px) {
 .__form__main_wrapper{
     margin: 0px!important;
     padding:0px !important;
}   

.flexible__modal__header{
    margin-left: 1em;
}
.modal-body{
    padding:0px!important;
    margin:0px!important;
}
}
</style>