<template>
    <div class="__wallet__header">
        <div class="__sms_">
            <h3 class="__header__">
                {{ sms_voice }}
            </h3>
            <p class="__text notranslate">
                {{$t('dashboard.wallet.subtitle')}}
                <!-- Please select a bundle offer -->
            </p>
        </div>
    </div>
</template>

<script>
import logintType from '../../../helpers/loginType'
export default {
    name: 'wallet-header',
    data() {
        return {
            currentView: 'buy_bundle'
        }
    },
    computed: {
        sms_voice() {
            return logintType() == 'sms' ? this.$t('dashboard.wallet.sms_bundles') : this.$t('dashboard.wallet.voice_bundles')
        }
    }
}
</script>

<style lang="scss" scoped>
@import url('../../../styles/wallet/__header.scss');
</style>