<template>
  <div class="mnotify-card">
      <div class="row">
          <div class="col-md-12 text-center my-3">
              <p class="text-ceneter" style="font-size: 13px">
                  {{message}}
              </p>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        message: {
            type: String,
            required: true
        }
    }
}
</script>

<style>

</style>