<template>
  <div>
    <div class="" v-if="Array.isArray(loadWallet) && loadWallet.length > 0">
      <HistoryCard
        v-for="data in loadWallet"
        :key="data.id"
        :data="data"
        type="load" />
      <router-link to="/wallet/wallet-history" class="view-all-link">
        <div class="view-all-container">
          <el-button type="primary">{{
            $t("dashboard.wallet.view_all")
          }}</el-button>
        </div>
      </router-link>
    </div>
    <NoDataComponent v-else :message="message" />
  </div>
</template>

<script>
  import HistoryCard from "../transactionhistory/transactionHIstoryCard.vue";
  import NoDataComponent from "./NodataComponent.vue";
  import store from "@/state/store.js";
  export default {
    name: "load-wallet",
    components: { HistoryCard, NoDataComponent },
    data() {
      return {
        message: this.$t("dashboard.wallet.no_wallet_deposit"),
      };
    },
    computed: {
      loadWallet() {
        let loadWallet = store.getters["wallet/depositBundleHistory"];
        //  filter allHistory where ref_id does not exist for load wallet
        let filteredHistory = loadWallet.filter((item) => {
          return item.hasOwnProperty("ref_id");
        });
        return filteredHistory.slice(0, 6);
      },
    },
  };
</script>

<style scoped>
  .view-all-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: 1rem;
  }

  .view-all-link {
    text-decoration: none;
  }
</style>
