<template>
  <div>
    <div class="" v-if="Array.isArray(bundlePurchase) && bundlePurchase.length > 0">
       <HistoryCard v-for="data in bundlePurchase" :key="data.id" :data="data"  />
        <router-link to="/wallet/bundle-history" class="view-all-link">
          <div class="view-all-container">
            <el-button type="primary">{{ $t('dashboard.wallet.view_all') }}</el-button>
          </div>
        </router-link>
    </div>
    <NoDataComponent v-else :message="message" />
  </div>
</template>

<script>
import HistoryCard from '../transactionhistory/transactionHIstoryCard.vue'
import store from '@/state/store.js'
import NoDataComponent from "./NodataComponent.vue"
export default {
 name:  "bundle-purchased",
 components: { 
  HistoryCard, 
  NoDataComponent
},
 data() {
   return {
     message: this.$t('dashboard.wallet.no_bundle')
   }
 },
 computed: {
   bundlePurchase() {
     let allHistory = store.getters['wallet/allHistory']
     //  filter allHistory where ref_id does not exist
     let filteredHistoryBundle = allHistory.filter((item) => {
       return item.hasOwnProperty("ref_id") == false
     })
     return filteredHistoryBundle.slice(0,6)
   }
 }

}
</script>

<style scoped>
.view-all-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block: 1rem;
}

.view-all-link {
  text-decoration: none;
}
</style>