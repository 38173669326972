<template>
  <div>
    <div class="" v-if="Array.isArray(allHistory) && allHistory.length > 0">
      <HistoryCard v-for="(data,index) in allHistory" :key="index" :data="data" type="load" />
    </div >
    <NoDataComponent v-else :message="message" />
  </div>
</template>

<script>
import HistoryCard from '../transactionhistory/transactionHIstoryCard.vue'
import NoDataComponent from './NodataComponent.vue'
import store from '@/state/store.js'
export default {
 name:  "load-wallet",
 components: {HistoryCard,NoDataComponent},
 data() {
   return {
     message: this.$t('dashboard.wallet.no_wallet_history')
   }
 },
beforeMount() {
  store.dispatch('wallet/walletBundleHistory')
  
},
computed: {
   allHistory() {
     return store.getters['wallet/allHistory']
   }
 }

}
</script>

<style>

</style>